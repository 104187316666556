<template>
  <div>
    <q-form ref="editForm">
      <c-card title="기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-text
              :required="true"
              :editable="editable"
              label="위생점검명"
              name="hygieneInspectionName"
              v-model="hygieneInspection.hygieneInspectionName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3">
            <c-rest-facilities
              :required="true"
              :disabled="isOld"
              :editable="editable"
              label="휴게시설"
              name="sopRestFacilitiesId"
              v-model="hygieneInspection.sopRestFacilitiesId">
            </c-rest-facilities>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-datepicker
              :required="true"
              :disabled="isOld"
              :editable="editable"
              type="year"
              default="today"
              label="년도"
              name="inspectionYear"
              v-model="hygieneInspection.inspectionYear"
            />
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-field
              :editable="editable"
              label="점검자"
              name="checkerId"
              v-model="hygieneInspection.checkerId">
            </c-field>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2">
            <c-plant
              :editable="editable"
              type="edit"
              label="사업장"
              name="plantCd"
              v-model="hygieneInspection.plantCd">
            </c-plant>
          </div>
        </template>
      </c-card>
    </q-form>
    <c-table
      ref="table"
      title="위생점검표"
      class="q-mt-sm"
      gridHeightAuto
      :merge="grid.merge"
      :columns="gridColumns"
      :data="hygieneInspection.results"
      :gridHeight="gridHeight"
      :filtering="false"
      :isExcelDown="false"
      :isFullScreen="false"
      :columnSetting="false"
      :editable="editable"
    >
      <template slot="customFilter">
        <q-chip square color="primary" text-color="white" icon="info_outline" style="vertical-align: baseline;">
          위생점검 할 월을 선택하세요.
        </q-chip>
        <c-select
          :editable="editable"
          :comboItems="monthItems"
          style="display:inline-block;width:100px"
          type="none"
          itemText="codeName"
          itemValue="code"
          name="stepCd"
          label=""
          v-model="month"
        ></c-select>
      </template>
    </c-table>
    <div class="popup-bottom-bar">
      <div class="popup-bottom-bar-btngroup">
        <q-btn-group outline >
          <c-btn
            v-show="editable && isOld"
            flat
            label="삭제"
            icon="delete_forever"
            @btnClicked="deleteInfo" />
          <c-btn
            v-show="editable"
            flat
            :url="saveUrl"
            :isSubmit="isSave"
            :param="hygieneInspection"
            :mappingType="mappingType"
            label="저장"
            icon="save"
            @beforeAction="saveInfo"
            @btnCallback="saveCallback" />
        </q-btn-group>
      </div>
      <div class="popup-bottom-bar-close">
        <q-btn flat color="gray" icon="arrow_back" @click="closePopUps" />
      </div>
    </div>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'hygieneInspectionDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        sopRestFacilitiesHygieneInspectionId: '',
      }),
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      hygieneInspection: {
        sopRestFacilitiesHygieneInspectionId: '',  // 휴게 시설 위생점검 일련번호
        plantCd: '',  // 사업장코드
        hygieneInspectionName: '',  // 위생점검명
        sopRestFacilitiesId: '',  // 휴게 시설 마스터 일련번호
        inspectionYear: '',  // 년도
        checkerId: '',  // 점검자
        stepCd: '',  // 진행단계
        regUserId: '',  // 등록자
        chgUserId: '',  // 수정자
        results: [], // 점검항목
      },
      grid: {
        merge: [
          { index: 0, colName: 'gubun' }
        ],
      },
      month: '',
      monthItems: [
        { code: '1', codeName: '1월', },
        { code: '2', codeName: '2월', },
        { code: '3', codeName: '3월', },
        { code: '4', codeName: '4월', },
        { code: '5', codeName: '5월', },
        { code: '6', codeName: '6월', },
        { code: '7', codeName: '7월', },
        { code: '8', codeName: '8월', },
        { code: '9', codeName: '9월', },
        { code: '10', codeName: '10월', },
        { code: '11', codeName: '11월', },
        { code: '12', codeName: '12월', },
      ],
      editable: true,
      isSave: false,
      detailUrl: '',
      itemListUrl: '',
      validUrl: '',
      saveUrl: '',
      deleteUrl: '',
      mappingType: 'PUT',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.sopRestFacilitiesHygieneInspectionId);
    },
    gridHeight() {
      let height = this.contentHeight - 200;
      if (!height || height < 600) {
        height = 600
      }
      return String(height) + 'px';
    },
    gridColumns() {
      let cols = [
        {
          name: 'gubun',
          field: 'gubun',
          label: '구분',
          align: 'center',
          style: 'width:25%',
          sortable: true,
        },
        {
          name: 'hygieneInspectionItemName',
          field: 'hygieneInspectionItemName',
          label: '위생점검 항목명',
          align: 'left',
          style: 'width:50%',
        },
      ];
      if (this.month) {
        let mon = this.month;
        cols.push(
          {
            name: `month${mon}`,
            field: `month${mon}`,
            label: `${mon}월`,
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
        )
      } else {
        let mon = this.$_.trimStart(this.$comm.getThisMonth().substring(5), '0')
        cols.push(
          {
            name: `month${mon}`,
            field: `month${mon}`,
            label: `${mon}월`,
            align: 'center',
            style: 'width:80px',
            type: 'select',
            comboItems: [
              { code: 'O', codeName: '양호', },
              { code: 'X', codeName: '불량', },
            ],
            setHeader: true,
          },
        )
      }
      
      return cols;
    }
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.detailUrl = selectConfig.sop.rtf.his.hygieneInspection.get.url
      this.itemListUrl = selectConfig.sop.rtf.his.hygieneInspectionItem.list.url
      this.validUrl = selectConfig.sop.rtf.his.hygieneInspection.valid.url
      this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.update.url
      this.deleteUrl = transactionConfig.sop.rtf.his.hygieneInspection.delete.url
      // code setting
      this.month = this.$_.trimStart(this.$comm.getThisMonth().substring(5), '0')
      // list setting
      this.getDetail();
    },
    getDetail() {
      if (this.isOld) {
        this.$http.url = this.$format(this.detailUrl, this.popupParam.sopRestFacilitiesHygieneInspectionId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.$_.extend(this.hygieneInspection, _result.data)
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
        },);
      } else {
        // 위생점검항목
        this.$set(this.hygieneInspection, 'checkerId', this.$store.getters.user.userId)
        this.$http.url = this.itemListUrl;
        this.$http.type = 'GET';
        this.$http.param = {
          plantCd: this.hygieneInspection.plantCd,
          useFlag: 'Y'
        };
        this.$http.request((_result) => {
          this.items = _result.data
          this.$set(this.hygieneInspection, 'results', this.$_.map(_result.data, item => {
            return {
              sopRestFacilitiesHygieneInspectionResultId: '',  // 휴게 시설 위생점검 항목결과 일련번호
              sopRestFacilitiesHygieneInspectionId: '',  // 휴게 시설 위생점검 일련번호
              sopRestFacilitiesHygieneInspectionItemId: item.sopRestFacilitiesHygieneInspectionItemId,  // 휴게 시설 위생점검 항목 일련번호
              gubun: item.gubun,  // 구분
              hygieneInspectionItemName: item.hygieneInspectionItemName,  // 휴게 시설 위생점검 항목명
              month1: null,  // 1월 점검결과
              month2: null,  // 2월 점검결과
              month3: null,  // 3월 점검결과
              month4: null,  // 4월 점검결과
              month5: null,  // 5월 점검결과
              month6: null,  // 6월 점검결과
              month7: null,  // 7월 점검결과
              month8: null,  // 8월 점검결과
              month9: null,  // 9월 점검결과
              month10: null,  // 10월 점검결과
              month11: null,  // 11월 점검결과
              month12: null,  // 12월 점검결과
              regUserId: this.$store.getters.user.userId,  // 등록자
              editFlag: 'C'
            }
          }))
        },);
      }
    },
    deleteInfo() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.popupParam.sopRestFacilitiesHygieneInspectionId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });

    },
    saveInfo() {
      if (this.isOld) {
        this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.update.url
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.sop.rtf.his.hygieneInspection.insert.url
        this.mappingType = 'POST';
      }
      
      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          this.$http.url = this.validUrl;
          this.$http.type = 'GET';
          this.$http.param = {
            inspectionYear: this.hygieneInspection.inspectionYear,
            sopRestFacilitiesId: this.hygieneInspection.sopRestFacilitiesId,
            sopRestFacilitiesHygieneInspectionId: this.hygieneInspection.sopRestFacilitiesHygieneInspectionId
          };
          this.$http.request((_result) => {
            if (_result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: '확인',
                message: '저장하시겠습니까?', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.hygieneInspection.regUserId = this.$store.getters.user.userId;
                  this.hygieneInspection.chgUserId = this.$store.getters.user.userId;

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: '안내', // 안내
                message: '입력하신 년도, 휴게시설의 위생점검표가 이미 존재합니다.\n다시 입력바랍니다.',
                type: 'warning', // success / info / warning / error
              });
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.$set(this.popupParam, 'sopRestFacilitiesHygieneInspectionId', result.data)
      this.getDetail();
    },
    closePopUp() {
      this.$emit('closePopup')
    },
  }
};
</script>